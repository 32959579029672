import React, { Component } from 'react';
import Axios from 'axios';
import RecordsList from './RecordsList';

export default class View extends Component {



    constructor(props) {
        super(props);
        this.state = { students: [], count: 0 };
    }

    componentDidMount() {
        Axios.get('http://pdp.1fortwo.com/php/list1.php')
            .then(response => {
                this.setState({ students: response.data[0], count: response.data[1] });
                // this.setState({ count: response.data });
            })
            .catch(function (error) {
                console.log(error);
            });
    }



    usersList() {
        return this.state.students.map(function (object, i) {
            return <RecordsList obj={object} key={i} />;
        });
    }

    /*     brojClanova() {
            return this.state.count.map(function () {
                return students.lenght;
            });
        } */

    render() {
        return (
            <div>
                <h3 align="center">Users List {`(${this.state.count})`}</h3>
                <table className="table table-striped" style={{ marginTop: 20 }}>
                    <thead>
                        <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th colSpan="2">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.usersList()}
                    </tbody>
                </table>
            </div>
        );
    }
}

