import React, { Component } from "react";

export default class Login extends Component {
    render() {
        return (
            <div>
                <p>Ovo je stranica logovanje</p>
            </div>
        )
    }
}