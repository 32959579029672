import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Insert from './components/Insert';
import Edit from './components/Edit';
import View from './components/View';
import Signin from './components/Signin';
import Login from './components/Login';
import Home from './components/Home';


// import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <Router>
      <div className="container">

        <nav className="navbar navbar-expand-lg navbar-lite bg-lite">
          <Link to={'/'} className="navbar-brand">React CRUD Example</Link>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link to={'/'} className="nav-link">Home</Link>
              </li>
              <li className="nav-item">
                <Link to={'/insert'} className="nav-link">Insert</Link>
              </li>
              <li className="nav-item">
                <Link to={'/view'} className="nav-link">View</Link>
              </li>
              <li className="nav-item">
                <Link to={'/login'} className="nav-link">LogIn</Link>
              </li>
              <li className="nav-item">
                <Link to={'/signin'} className="nav-link">SignIn</Link>
              </li>
            </ul>
          </div>
        </nav>

        <h6>Welcome to React CRUD Tutorial</h6>

        <Switch>
          <Route exact path='/insert' component={Insert} />
          <Route path='/edit/:id' component={Edit} />
          <Route path='/view' component={View} />
          <Route path='/login' component={Login} />
          <Route path='/signin' component={Signin} />
          <Route path='/' component={Home} />
        </Switch>

      </div>
    </Router>

  );
}

export default App;
