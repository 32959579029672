import React, { Component } from "react";

export default class Home extends Component {
    render() {
        return (
            <div>
                <p>Ovo je Home page</p>
            </div>
        )
    }
}